export default {
  ///防抖(onresize)
  throttle(func, delay, atleast) {
    var timer = null;
    var startTime = null;
    return function () {
      var context = this;
      var args = arguments;
      var curTime = +new Date();
      if (!startTime) {
        startTime = curTime;
      }
      clearTimeout(timer);
      if (atleast && curTime - startTime >= atleast) {
        func.apply(context, args);
        startTime = curTime;
      } else {
        timer = setTimeout(function () {
          func.apply(context, args);
          startTime = null;
        }, delay);
      }
    };
  },
};
