import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  routes,
});

///title、meta配置
router.beforeEach((to, from, next) => {
  let meta = to.meta;
  let metaElem = function (name, content) {
    let head = document.head || document.getElementsByTagName("head")[0];
    let meta = document.createElement("meta");
    meta.name = name;
    meta.content = content;
    head.appendChild(meta);
  };
  if (meta.title) {
    document.title = meta.title;
  }
  if (meta.keyword) {
    metaElem("keyword", meta.keyword);
  }
  if (meta.description) {
    metaElem("description", meta.description);
  }
  next();
});

export default router;
