import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
///import VueCookie from "vue-cookie";
import api from "@/api";
import "@/icons";
import "lib-flexible/flexible";
import "@/assets/scss/index.scss";
import types from "@/store/types";
import utils from "@/utils";
import GetScreen from "@/components/GetScreen";

Vue.config.productionTip = false;
///开发环境, 适配mockjs模拟数据
if (process.env.NODE_ENV !== "production") {
  require("@/mock");
}
///实例注入
///Vue.use(VueCookie);
///全局组件
Vue.component("get-screen", GetScreen);
///挂载全局
Vue.prototype.$api = api;
Vue.prototype.$types = types;
Vue.prototype.$utils = utils;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
