import http from "@/utils/httpRequest";

export default {
  home(argumentsObj) {
    let obj = {
      url: http.adornUrl("/home.json"),
      method: "get",
      params: http.adornParams(argumentsObj),
    };
    return http(obj);
  },
  list(argumentsObj) {
    let obj = {
      url: http.adornUrl("/list.json"),
      method: "get",
      params: http.adornParams(argumentsObj),
    };
    return http(obj);
  },
};
