import axios from "axios";
import qs from "qs";
import merge from "lodash/merge";

const config = require("../../package").config;

///创建axios 实例
const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    ///这里可以自定义一些config 配置
    return config;
  },
  (error) => {
    ///这里处理一些请求出错的情况
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  (response) => {
    ///这里处理一些 response 正常放回时的逻辑
    return response;
  },
  (error) => {
    ///这里处理一些response 出错时的逻辑
    return Promise.reject(error);
  }
);

/**
 * 请求地址处理
 */
http.adornUrl = (actionName) => {
  ///非生产环境, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  return (
    (process.env.NODE_ENV !== "production" ? "/proxyApi" : config.prod) +
    actionName
  );
};

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  // var defaults = {
  //     t:new Date().getTime()
  // };
  // return openDefultParams ? merge(defaults, params) : params;
  return openDefultParams ? params : params;
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = "json") => {
  var defaults = {
    t: new Date().getTime(),
  };
  data = openDefultdata ? merge(defaults, data) : data;
  return contentType === "json" ? JSON.stringify(data) : qs.stringify(data);
};

export default http;
