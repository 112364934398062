import TYPES from "../types";
import localstore from "store2";

export default {
  namespaced: false, ///false:全局变量 true:模块变量
  state: {
    headerNav: localstore("headerNav") || {},
    loopPitcures: {},
    tabsHead: {},
    tabsCont: {},
    plateViews: [],
  },
  mutations: {
    ["headerNav"](state, { headerNav }) {
      localstore.set("headerNav", headerNav);
      state.headerNav = headerNav;
    },
    ["loopPitcures"](state, { loopPitcures }) {
      state.loopPitcures = loopPitcures;
    },
    ["tabsHead"](state, { tabsHead }) {
      state.tabsHead = tabsHead;
    },
    ["tabsCont"](state, { tabsCont }) {
      state.tabsCont = tabsCont;
    },
    ["plateViews"](state, { plateViews }) {
      state.plateViews = plateViews;
    },
  },
  actions: {
    ///获取首页数据
    [TYPES.GET_HOME_DATA]({ commit }, { vueThis }) {
      vueThis.$api["home"]({
        ///参数
      })
        .then(({ data }) => {
          if (data && data.code === 1) {
            let result = data.result;
            commit("headerNav", { headerNav: result.headerNav });
            commit("loopPitcures", { loopPitcures: result.loopPitcures });
            commit("tabsHead", { tabsHead: result.tabs.head });
            commit("tabsCont", { tabsCont: result.tabs.cont });
            commit("plateViews", { plateViews: result.plateViews });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
