import Vue from "vue";
import Vuex from "vuex";
// import localstore from "store2";
import home from "./modules/home";
import list from "./modules/list";
import TYPES from "./types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mobileMaxWidth: 500, ///移动端与桌面端界限(小于500:移动端，大于500:桌面端)
    throttleTime: 300, ///防抖间隔(ms)
    screenWidth: 0, ///设备宽度
    screenHeight: 0, ///设备高度
    deviceType: true, ///设备类型(true:桌面端, false:移动端)
  },
  mutations: {
    ///存储设备宽高
    [TYPES.SAVE_SCREEN](state, { screenWidth, screenHeight }) {
      if (screenWidth > state.mobileMaxWidth) {
        state.deviceType = true;
      } else {
        state.deviceType = false;
      }
      state.screenWidth = screenWidth;
      state.screenHeight = screenHeight;
    },
  },
  actions: {},
  modules: {
    home,
    list,
  },
});
