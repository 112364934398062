let routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      title: "首页 - 乐融官网",
    },
  },
  {
    path: "/tvlist",
    name: "tvlist",
    component: () => import("@/views/ProductLists.vue"),
    meta: {
      title: "超级电视 - 乐融官网",
    },
  },
  {
    path: "/aiot",
    name: "aiot",
    component: () => import("@/views/SmartHome.vue"),
    meta: {
      title: "智家 - 乐融官网",
    },
  },
  {
    path: "/eui",
    name: "eui",
    component: () => import("@/views/EUI.vue"),
    meta: {
      title: "EUI - 乐融官网",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/CompanyIntroduction.vue"),
    meta: {
      title: "公司简介 - 乐融官网",
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

export default routes;
