<template>
  <div class="get-screen-wrapper"></div>
</template>
<script>
export default {
  name: "GetScreen",
  mounted() {
    let that = this;
    that.saveScreen();
    ///延迟设置为零，否则 resize 事件获取值将是上一次计算结果
    window.addEventListener(
      "resize",
      that.$utils.throttle(that.saveScreen, 0),
      false
    );
  },
  methods: {
    ///存储设备宽高
    saveScreen() {
      let that = this;
      let doc = document;
      let screenWidth = doc.documentElement.clientWidth || doc.body.clientWidth;
      let screenHeight =
        doc.documentElement.clientHeight || doc.body.clientHeight;
      that.$store.commit(that.$types.SAVE_SCREEN, {
        screenWidth,
        screenHeight,
      }); ///计算设备宽高后存储到Vuex
    },
  },
};
</script>
<style lang="scss">
.get-screen-wrapper {
  position: absolute;
  top: -100em;
  left: -100em;
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
