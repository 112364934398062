import TYPES from "../types";
import localstore from "store2";

export default {
  namespaced: false, ///false:全局变量 true:模块变量
  state: {
    radios: localstore("radios") || [],
    products: localstore("products") || [],
    radioCheckType: 0, ///radio类型值
  },
  mutations: {
    ["radios"](state, { radios }) {
      state.radios = radios;
      localstore.set("radios", radios);
    },
    ["products"](state, { products }) {
      state.products = products;
      localstore.set("products", products);
    },
    ///存储radio类型值
    [TYPES.SAVE_CHECK_TYPE](state, { radioCheckType }) {
      state.radioCheckType = radioCheckType;
    },
  },
  actions: {
    ///获取列表数据
    [TYPES.GET_LIST_DATA]({ commit }, { vueThis }) {
      vueThis.$api["list"]({
        ///参数
      })
        .then(({ data }) => {
          if (data && data.code === 1) {
            let result = data.result;
            commit("radios", { radios: result.radios });
            commit("products", { products: result.products });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
